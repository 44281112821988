@import './variables';

.nav-lg {
	visibility: hidden;
	position: absolute;

	@include media($navigationBreakpoint) {
		visibility: visible;
		position: relative;
	}
}

.nav-sm {
	@include media($navigationBreakpoint) {
		visibility: hidden;
		display: none !important;
	}
}

.Navigation {
	position: fixed;
	height: $navigationHeight-sm;
	width: 100%;
	max-width: 100vw;
	top: 0;
	z-index: $navigation-z-index;

	@include media($navigationBreakpoint) {
		position: relative;
		height: auto;
		transition: top 0.8s ease-in-out, height 0.3s ease-in-out;
	}

	&.Navigation--hasHero {
		position: absolute;
		background-color: transparent;
	}

	&.has-Secondary {
		@include media($navigationBreakpoint) {
			position: absolute;
		}
	}

	.itinerary-link {
		display: none;
	}

	&-bodySpacer {
		display: block;
		position: relative;
		width: 100%;
		height: 60px;
		background-color: $black;
		transition: height 0.4s ease-in;

		@include media($navigationBreakpoint) {
			height: 0;
		}

		&--hasHero {
			height: 30px;
		}
	}

	&-emptyCAW {
		width: 130px;
		height: 1px;
	}

	&-sticky-footer {
		position: fixed;
		bottom: 0;
		right: 0;
		left: 0;
		padding: 12px 15px;
		height: 72px;
		background: $white;
		border-top: 1px solid $black;

		&-gradient {
			pointer-events: none;
			position: fixed;
			right: 0;
			left: 0;
			height: 60px;
			bottom: 67px;
			background-image: linear-gradient(
				180deg,
				rgba(255, 255, 255, 0),
				rgba(0, 0, 0, 0.1),
				rgba(0, 0, 0, 0.15),
				rgba(0, 0, 0, 0.2),
				rgba(0, 0, 0, 0.3)
			);
		}
	}

	&-sm {
		display: flex;
		position: fixed;
		align-items: center;
		background-color: $white;
		height: $navigationHeight-sm;
		justify-content: space-between;
		padding: 0 14px 0 20px;
		border-bottom: 1px solid $separatorLight;
		top: 0;
		left: 0;
		width: 100%;

		&.is-Stick {
			position: fixed;
		}
		&.Navigation--atTop {
			position: relative;
		}
		@include rtl {
			padding: 0 20px 0 14px;
		}

		@include viewport(medium) {
			height: 60px;
			padding: 0 22px 0 15px;

			@include rtl {
				padding: 0 15px 0 22px;
			}
		}

		.Navigation {
			&-logo {
				margin: 0;
				color: $black;
				font-size: 40px;
				line-height: 0;
				font-weight: bold;

				&::before {
					@include icon('icon-tree');
					display: inline-block;
					line-height: 1;

					@include wls {
						content: ' ';
						background-image: var(--wls-dark-logo);
						background-size: contain;
						height: 50px;
						width: 75px;
					}
				}

				// Temporary hardcoding text for Beveraly Wilshire. See MTC-1318
				&.BeverlyWilshire::before {
					content: ' ';
					background-image: url('/alt/fshr/design3/images/BEV_logo_dark.png');
					background-size: contain;
					height: 50px;
					width: 75px;
				}
			}

			&-cta {
				position: absolute;
				bottom: 50%;
				right: 50%;
				transform: translate(50%, 50%);
				color: $black;
				background-color: $white;
				border-color: $black;

				&.is-Sticky {
					width: 100%;
					height: 43px;
					transform: none;
					position: static;
					font-family: $sansFont;
					color: $black;
					background-color: $white;
					border-color: $black;
					font-size: 0.9375rem;
					line-height: 19px;
					letter-spacing: 4.5px;
					font-weight: bold;
				}

				&:focus,
				&:hover {
					color: $white;
					background-color: $black;
				}

				@include viewport(medium) {
					color: $white;
					background-color: $black;
					border-color: $black;

					&:focus,
					&:hover {
						color: $black;
						background-color: $white;
					}
				}
			}

			&-menuButton {
				@include iconButton('before', 'icon-hamburger', 'light');
				font-size: 14px;

				.Navigation--dark & {
					@include grayHover('dark');
					&::before {
						color: $white;
					}
					color: $white;
				}
				.Navigation-menu-cta-button-label {
					top: -14px;
					right: -1px;
					position: relative;

					@include sansBold;
					font-size: 0.45rem;
					letter-spacing: 0.25em;
					line-height: 1.33333em;
					text-transform: uppercase;
					overflow: visible;
					display: block;
				}
			}
		}
		.booking-flow-mode & {
			height: auto;
			@include viewport(medium) {
				height: auto;
			}
		}

		.CartStatus-placeholder {
			position: absolute;
			right: 60px;
			top: 18px;

			@include viewport(medium) {
				right: 67px;
			}
		}
	}

	&-subscribeBar--sm.SubscribeBar {
		position: fixed;
		top: 60px;

		@media only screen and (min-width: 1100px) {
			display: none;
		}
	}

	&-lg {
		width: 100%;
		transition: transform 0.3s ease-in;

		&.is-Stick {
			position: fixed;
			top: 0;
			transform: translateY(-$navigationTopBarHeight + -1px);

			.has-Secondary & {
				transform: translateY(-$navigationTopBarHeight + -$navigationHeight-lg-collapsed);

				.Navigation-logoContainer {
					.Navigation-logo--animate {
						opacity: 0 !important;
					}
				}
			}
		}

		&.is-Stick.is-StickExpanded {
			transform: translateY(0);
		}

		.CartStatus-placeholder {
			position: relative;
			left: 50px;
			bottom: -3px;
		}
	}

	&-background {
		position: relative;
		background-color: $black;
		border-bottom: 1px solid $gray3;
		transition: background 0.3s ease-in-out, backdrop-filter 0.3s ease-in-out;

		.Navigation--hasHero & {
			background-color: transparent;
			border-bottom: none;
		}

		z-index: 1;

		&-filter-fallback {
			display: none;
		}

		.is-Stick & {
			border-bottom: none;
			background: rgba(0, 0, 0, 0.9);

			&-filter-fallback {
				filter: blur(5px);
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				z-index: -1;
			}
		}

		.has-Secondary.is-Stick & {
			background: transparent;
		}

		.has-Secondary.is-Stick .is-StickExpanded & {
			background: rgba(0, 0, 0, 0.9);
		}
	}

	&--hasHero .Navigation-backgroundGradient {
		position: absolute;
		width: 100%;
		height: 200px;
		background: linear-gradient(180deg, black 0%, rgba(0, 0, 0, 0) 100%);
		z-index: -1;
		pointer-events: none;
		opacity: 1;
		transition: opacity 0.3s ease-in-out;

		&::before {
			content: '';
			position: absolute;
			display: block;
			width: 100%;
			height: 116px; // 116px or 110px depending on whether page is scrolled down
			background: linear-gradient(180deg, black 0%, rgba(0, 0, 0, 0) 100%);
		}

		&::after {
			content: '';
			position: absolute;
			display: block;
			width: 100%;
			height: 44px;
			top: 14px;
			background: linear-gradient(180deg, black 0%, rgba(0, 0, 0, 0) 100%);
		}
	}

	&--hasHero.is-Stick .Navigation-backgroundGradient {
		opacity: 0;
	}

	&-topBar {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		height: $navigationTopBarHeight;
		padding: 14px 23px 0;

		@include rtl {
			padding: 4px 23px 0;
		}

		.Navigation-link:not(:last-child) {
			margin-right: 30px;
		}

		.LanguageToggle,
		.currency-dropdown {
			color: $white;
			border-color: $whover;

			@include rtl {
				padding-left: 0;
				padding-right: 0px;
			}

			&:hover,
			&:hover::after {
				color: $bhover;
			}

			&-select {
				@include typography(cc4);
				max-width: 110px;
				padding-right: 20px;
				padding-bottom: 0;
				text-align: right;
				text-align-last: right;

				.isEdgeOrIE & {
					font-size: 0.7rem;
					vertical-align: middle;
				}
			}

			@supports (-ms-ime-align: auto) {
				&-select {
					font-size: 0.7rem;
					text-align: center;
					vertical-align: middle;
				}
			}
		}
		.CurrencyDropdown {
			@include rtl {
				margin-left: 0;
				margin-right: 23px;
			}
		}

		.Navigation-link,
		.LanguageToggle-select {
			font-size: 10px;
			letter-spacing: 2.05px;
		}
	}

	&-link {
		color: $white;

		@include typography(cc4);

		&:hover {
			color: $bhover;
		}

		.isEdgeOrIE & {
			letter-spacing: normal;
			font-size: 0.7rem;
		}

		.overlay {
			min-width: 100px;
		}
	}

	&-title {
		padding-right: 24px;

		@include rtl {
			padding-right: 0;
			padding-left: 24px;
		}
	}

	&-subTitle {
		font-size: 16px;
		text-transform: none;
		position: relative;
		top: -2px;
		padding-left: 24px;
		color: $whover;

		&:hover {
			color: $bhover;
		}

		&::before {
			content: '';
			position: absolute;
			width: 1px;
			height: 19px;
			top: 3px;
			left: 0;
			background-color: $separatorLight;
		}

		@include rtl {
			padding-left: 0;
			padding-right: 24px;
			top: 0;
			line-height: 1.2em;

			&::before {
				left: auto;
				right: 0;
				top: 0;
			}
		}
	}

	&-bar {
		display: none;
		align-items: center;
		justify-content: space-between;
		padding: 12px 30px;
		transition: height 0.5s;

		@include media($navigationBreakpoint) {
			display: flex;
			min-height: $navigationHeight-lg-collapsed;
		}
	}

	&-logo {
		margin: 0 15px;
		color: $white;
		font-size: 55px;
		line-height: 0;
		font-weight: bold;

		&::before {
			@include icon('icon-tree');
			display: inline-block;
			line-height: 1;

			@include wls {
				content: ' ';
				background-image: var(--wls-logo);
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
				height: 57px;
				width: 100px;
			}
		}

		// Temporary hardcoding text for Beveraly Wilshire. See MTC-1318
		&.BeverlyWilshire::before {
			content: ' ';
			background-image: url('/alt/fshr/design3/images/BEV_logo_light.png');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			height: 57px;
			width: 100px;
		}

		&Container {
			flex: 1 0 auto;

			&-centered-links {
				position: absolute;
			}
		}
	}

	&-links {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		&Container {
			flex: 1 1 auto;
			margin-left: 30px;
			padding: 6px 0 6px 15px;
			width: 100%;
			min-width: 0;

			&-centered-links {
				margin-left: 0px;
				margin-right: 0px;
				padding-left: 0px;
				padding-right: 0px;
			}

			@include rtl {
				margin-right: 30px;
				margin-left: 0;
				padding-right: 15px;
				padding-left: 0;
			}

			.Navigation--isPropertyNav & {
				padding-left: 20px;
				border-left: 1px solid #979797;

				@include rtl {
					padding-left: 0;
					padding-right: 20px;
					border-left: none;
					border-right: 1px solid #979797;
				}
			}

			.Navigation-pageName {
				display: inline-block;
				margin-bottom: 13px;
				color: $white;
				line-height: 1em;
				letter-spacing: 3px;
			}
		}
	}

	&-mainLinks {
		display: flex;
		align-items: center;
		line-height: 1em;
		width: 100%;
		&Container {
			flex: 1 1 auto;
			width: 100%;
			.Navigation--moreLinkClicked &,
			.Navigation--moreLinkExpanded & {
				overflow: visible;
			}
		}
	}

	&-more {
		pointer-events: none;
		margin: 0;

		&Btn {
			color: $white;
			line-height: 1.33333em;
			vertical-align: middle;
			transition: background-color 0.3s ease-out, color 0.3s ease-out,
				border-color 0.3s ease-out;

			&::after {
				content: '';
				height: 40px;
				width: 100px;
				position: absolute;
				right: -15px;
				top: 10px;
			}
			&--active {
				.Navigation-moreBtn {
					border-bottom: 1px solid $white;

					&:hover {
						border-bottom-color: $whover;
					}
				}
			}
		}
	}

	&-overflowLinks {
		position: absolute;
		top: 45px;
		left: 52%;
		padding: 42px 30px 28px;
		min-width: 425px;
		background-color: $white;
		border-top: 1px solid $separatorLight;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
		transform: translateX(-52%) translateZ(0) perspective(1px);
		opacity: 0;
		z-index: 2;
		visibility: hidden;
		pointer-events: none;
		backface-visibility: hidden;
		-webkit-font-smoothing: subpixel-antialiased;

		&:hover,
		.Navigation--moreLinkClicked &,
		.Navigation--moreLinkExpanded & {
			visibility: visible;
			pointer-events: auto;
			opacity: 1;
		}

		&--left {
			margin-bottom: 17px;
		}

		&--wrapped {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.Navigation-overflowLinks--left {
				margin-bottom: 0;
			}
		}

		&--left,
		&--right {
			flex: 1 0;
			padding: 0 30px;
		}

		&::after {
			content: '';
			border-color: transparentize($color: $separatorLight, $amount: 0.5);
			border-style: solid;
			border-width: 0;
			position: absolute;
			background-color: $white;
			transform: rotate(45deg);
			margin: 0 auto;
			left: 0;
			right: 0;
			z-index: -1;
			border-left-width: 1px;
			border-top-width: 1px;
			top: -10px;
			width: 20px;
			height: 20px;
		}
	}

	&-cta {
		padding: 12px 20px;

		.Navigation-lg & {
			flex: 0 0 190px;
			margin-left: 90px;

			@include rtl {
				margin-left: 0;
				margin-right: 90px;
			}

			color: $black;
			background-color: $white;
			border-color: $white;

			&:focus,
			&:hover {
				color: $white;
				background-color: $black;
			}
		}
	}

	&-emptyCAW {
		width: 130px;
	}
}

@supports (backdrop-filter: blur(1px)) {
	.Navigation {
		&-background {
			.is-Stick & {
				background: rgba(0, 0, 0, 0.85);
			}
		}
		&-background-blur {
			.is-Stick & {
				backdrop-filter: blur(13px);
			}
		}
	}
}

@supports not (backdrop-filter: blur(1px)) {
	.Navigation {
		&-background {
			.is-Stick & {
				background: rgba(0, 0, 0, 0.9);
			}
		}
	}
}

.NavigationOverlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: $navigationOverlayOffset;
	pointer-events: none;
	z-index: 10;

	@include rtl {
		left: 0;
		right: $navigationOverlayOffset;
	}

	&--Opened {
		pointer-events: initial;
	}

	&-CloseCTA {
		position: absolute;
		top: 0;
		right: 0;
		margin-top: 15px;
		margin-right: 25px;
		visibility: hidden;
		opacity: 0;
		transition: color 0.3s, opacity 0.3s, visibility 0.3s;

		.NavigationOverlay--Opened & {
			visibility: visible;
			opacity: 1;
		}

		@include rtl {
			left: 0;
			right: auto;
			margin-left: 25px;
			margin-right: 0;
		}
	}

	&-Background {
		background-color: rgba($black, 0.5);
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.3s, visibility 0.3s;

		.NavigationOverlay--Opened & {
			visibility: visible;
			opacity: 1;
		}
	}

	&-Content {
		background-color: $white;
		color: $black;
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		max-width: 470px;
		transition: transform 0.3s ease-in, visibility 0s linear 0.3s;
		transform: translateX(100%);
		visibility: hidden;

		@include rtl {
			left: 0;
			right: auto;
			transform: translateX(-100%);
		}

		.NavigationOverlay--Opened & {
			transition: transform 0.3s ease-in, visibility 0s linear 0s;
			transform: translateX(0);
			visibility: visible;
		}
	}

	&-InnerContent {
		position: relative;
		overflow: auto;
		padding: 0 ($navigationOverlayHorizontalPadding + $navigationOverlayOffset) 0
			$navigationOverlayHorizontalPadding;

		@include rtl {
			padding: 0 $navigationOverlayHorizontalPadding 0
				($navigationOverlayHorizontalPadding + $navigationOverlayOffset);
		}
	}

	&-Logo {
		color: $black;
		display: inline-block;
		text-decoration: none;
		font-size: 58px;
		line-height: 1;
		margin-top: 8px;
		margin-bottom: 0;

		.Navigation--light & {
			color: $black;
		}

		&::before {
			@include icon('icon-tree');
			display: block;

			@include wls {
				content: ' ';
				background-image: var(--wls-dark-logo);
				background-size: contain;
				background-repeat: no-repeat;
				height: 100px;
				width: 130px;
			}
		}

		&.BeverlyWilshire::before {
			content: ' ';
			background-image: url('/alt/fshr/design3/images/BEV_logo_dark.png');
			background-size: contain;
			background-repeat: no-repeat;
			height: 100px;
			width: 130px;
		}
	}

	&-Title {
		display: inline-block;
		text-decoration: none;
		transition: color 0.3s;
		color: $black;

		&:hover {
			color: $bhover;
		}

		&--propertyTypeName {
			margin: 0;
			margin-bottom: 8px;
		}

		&--propertyTitle {
			margin: 0;
			font-family: $sansFont;
			font-size: 28px;
			line-height: 30px;
			font-weight: normal;
			letter-spacing: 3.5px;
		}
	}

	&-TopBar {
		align-items: center;
		background-color: $white;
		display: flex;
		flex-shrink: 0;
		flex-basis: 80px;
		flex-wrap: wrap;
		top: 0;
		right: 0;
		padding: 0 ($navigationOverlayHorizontalPadding + $navigationOverlayOffset + 20px) 0
			($navigationOverlayHorizontalPadding - 3px);
		width: 100%;
		z-index: 1;
		justify-content: space-between;
		gap: 10px;

		.CurrencyDropdown {
			margin-left: 0px;
		}

		@include rtl {
			padding: 0 $navigationOverlayHorizontalPadding 0
				($navigationOverlayHorizontalPadding + $navigationOverlayOffset);
		}

		@include viewport(medium) {
			max-width: 470px;
		}
		@media (max-width: 414px) {
			padding: 25px ($navigationOverlayHorizontalPadding + $navigationOverlayOffset + 20px)
				20px ($navigationOverlayHorizontalPadding - 3px);
			flex-basis: auto;
		}
	}

	&-TopLink {
		border: 0;
		color: $whover;
		font-size: 10px;
		letter-spacing: 2px;
		padding: 0;

		&:hover {
			color: $bhover;
		}
	}

	&-TopLinkContainer {
		display: flex;
		margin-right: 15px;

		@include rtl {
			margin-left: 30px;
			margin-right: 0;
		}

		&:last-of-type {
			margin-right: 0;
		}

		.CTA,
		.LanguageToggle-select,
		.currency-dropdown select {
			color: $black;
			font-size: 13px;
			line-height: 17px;
			font-family: $sansFont;
			letter-spacing: 2.04px;
			font-weight: 200;
			white-space: nowrap;

			@include media($navigationOverlayTopLinkBreakpoint) {
				font-family: 'Neue Helvetica', helvetica, sans-serif;
				font-weight: 400;
				font-style: normal;
				font-size: 0.625rem;
				letter-spacing: 0.3em;
				line-height: 1.3em;
				text-transform: uppercase;
			}
		}

		.LanguageToggle,
		.currency-dropdown {
			color: $whover;
			border-color: $whover;
			display: flex;

			&::after {
				top: 4px;
			}

			&:hover,
			&:hover::after {
				color: $bhover;
			}

			&-select {
				padding-bottom: 0;
				padding-right: 18px;
				max-width: 99px;

				@include media($navigationOverlayTopLinkBreakpoint) {
					max-width: 82px;
					text-align-last: right;
					text-overflow: ellipsis;
					text-wrap: nowrap;
					white-space-collapse: preserve;
				}

				@include rtl {
					padding-left: 25px;
					padding-right: 0;
					text-align-last: left;
				}
			}
		}
	}

	&-LinksContainer {
		padding: 35px 0;

		&--borders {
			border-top: 1px solid black;
		}
	}

	&-LinksTitle {
		color: $whover;
		margin-top: 0;
		margin-bottom: 20px;
	}
}

.recaptcha-container.hasStickyFooter {
	.grecaptcha-badge {
		bottom: 86px !important;

		@include media($navigationBreakpoint) {
			bottom: 14px !important;
		}
	}
}
